export default {
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Контакти"])},
  "main-block": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Притулок у Великобританії 🇺🇦🇬🇧"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уряд Великобританії запустив програму переселення для біженців"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.gov.uk/government/publications/welcome-a-guide-for-ukrainians-arriving-in-the-uk.uk"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["українців та їхніх найближчих родичів."])}
    ],
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо вам потрібна безкоштовна допомога волонтерів у пошуку спонсора або заповнення документів"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Заповніть, будь ласка, форму"])}
  },
  "info-block": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Інформація"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Житло надають звичайні британці та благодійні організації."])},
    "details": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хто має право:"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Громадяни України, які проживали в Україні до 1 січня 2022 року та найближчі члени їхніх сімей."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Біженці, які прибувають за цією схемою, зможуть отримати"])},
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Безкоштовну візу, щоб жити та працювати у Великій Британії 3 роки"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тимчасове безкоштовне житло на строк від 6 місяців"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Доступ до медицини, посібників, навчання англійської мови, допомогу у пошуку роботи."])}
        ]
      }
    ]
  },
  "forms-block": {
    "sponsor": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хочу стати Спонсором"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Якщо ви проживаєте в Великобританії та хочете розмістити в себе українську сім'ю, будь-ласка, заповніть форму тут"])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися як Спонсор"])}
    },
    "volunteer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хочу стати Волонтером"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нам дуже потрібні україно- на англомовні волонтери, щоб прискорити процес опрацювання анкет та координацію хостів та сімей з України. Якщо ви можете допомогти нам з координацією та підбором хостів для українських сімей -- будь-ласка, заповніть цю форму "])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зареєструватися як волонтер"])}
    }
  }
}