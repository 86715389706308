import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import RouterViewComponent from '../components/RouterViewComponent';
import getBrowserLocale from "@/utils/getBrowserLocale"
const browserLocale = getBrowserLocale({ countryCodeOnly: true });

const routes = [{
        path: '/',
        redirect: `/${browserLocale}`
    },
    {
        path: '/:lang',
        component: RouterViewComponent,
        redirect: '/:lang/',
        children: [{
                path: '/:lang/',
                name: 'home',
                component: HomeView
            },
            {
                path: '/:lang/about',
                name: 'about',
                component: () =>
                    import ('../views/AboutView.vue')
            }
        ]
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router