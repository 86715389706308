export default {
  "contacts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contacts"])},
  "main-block": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homes for Ukraine 🇺🇦🇬🇧"])},
    "description": [
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK government has launched"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.gov.uk/government/publications/welcome-a-guide-for-ukrainians-arriving-in-the-uk"])},
      (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["programme <br />for refugees, Ukrainian citizens and their families."])}
    ],
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you need free help from our volunteers, <br />please apply by filling out the form"])},
    "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply"])}
  },
  "info-block": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information"])},
    "paragraph": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The accommodation is provided by British people and charity organizations."])},
    "details": [
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Who is eligible"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Citizens of Ukraine, living in the Ukraine until 1 January 2022 and their immediate family members."])}
      },
      {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What you will get"])},
        "description": [
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE visa allowing to live and work in the UK for 3 years"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FREE temporary accommodation for at least 6 months"])},
          (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Access to medical services, benefits, English courses and support in finding a job"])}
        ]
      }
    ]
  },
  "forms-block": {
    "sponsor": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to apply as a Sponsor "])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you are based in the UK and want to host a Ukrainian family, please apply here:"])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply as a Sponsor"])}
    },
    "volunteer": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I want to volunteer"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you can volunteer and help us with coordinating the UK hosts and Ukrainian families, please fill in the Volunteer application form"])},
      "button-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apply as a Volunteer"])}
    }
  }
}