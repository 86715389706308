<template>
  <div class="px-0 mx-0">
    <div class="md:pt-28 sm:pt-24 pt-8 flex flex-col items-center relative">
        <a class="lang px-6 py-3 bg-white border-slate-200 border" href="" @click.prevent="changeLocale">{{ localeTitle }}</a>
        <div class="pt-24 tsh">
            <div class="flex flex-col justify-between min-h-full">
                <div class="flex lg:flex-row hrp">
                    <div class="xl:basis-5/12 basis-12/12">
                        <div class="prose prose-slate prose-md md:prose-xl w-full">
                            <h1 class="ugradient">{{ $t('main-block.title') }}</h1>

                            <div class="mb-12">
                                <p class="font-semibold">
                                    {{ $t('main-block.description[0]') }}
                                    <a :href="$t('main-block.description[1]')" target="_blank"
                                        class="font-semibold">Homes
                                        for
                                        Ukraine </a>
                                        <span v-html="$t('main-block.description[2]')"></span>
                                </p>
                            </div>

                            <p v-html="$t('main-block.subtitle')"></p>
                            <a class="button button-primary" href="https://forms.gle/su5emyBa943HxMpa6">{{ $t('main-block.button-text') }}</a>
                        </div>
                    </div>
                    <div class="ml-8 hidden xl:basis-6/12 pb-12 xl:flex flex-col justify-center content-center">
                        <img :src="require('../assets/10142-3-min.jpg')" class="" alt="">
                    </div>
                </div>
                <div class="md:flex lg:flex-row hrp mt-24">
                    <div class="xl:basis-6/12 md:basis-full pr-12 pb-12 text-center">
                        <h2 class="font-bold mb-12 text-2xl">{{ $t('forms-block.sponsor.title') }}</h2>
                        <p class="mb-12">{{ $t('forms-block.sponsor.description') }}</p>
                        <a class="button button-primary" href="https://docs.google.com/forms/d/e/1FAIpQLSeL8_an7rWv3qLGvZRq7eharXHbv-e4uMnYfm-TaeUAKMI7LQ/viewform">{{ $t('forms-block.sponsor.button-text') }}</a>
                    </div>
                    <div class="xl:basis-6/12 md:basis-full pr-12 pb-12 text-center">
                        <h2 class="font-bold mb-12 text-2xl">{{ $t('forms-block.volunteer.title') }}</h2>
                        <p class="mb-12">{{ $t('forms-block.volunteer.description') }}</p>
                        <a class="button button-primary" href="https://docs.google.com/forms/d/e/1FAIpQLSfulTD23mcA6V1HnfY7f1Jk2gLpxdO_kcYkQMzZqVlC4vKpSg/viewform">{{ $t('forms-block.volunteer.button-text') }}</a>
                    </div>
                </div>

                <div class="md:flex md:flex-row md:justify-start md:mt-32 mt-16 mb-24 hrp">
                    <div class="xl:basis-7/12 basis-9/12 pr-24">
                        <div class="prose prose-slate prose-md md:prose-xl">
                            <h2>{{ $t('info-block.title') }}</h2>
                            <p>{{ $t('info-block.paragraph') }}</p>
                            <details open class="mb-4">
                                <summary class="cursor-pointer font-bold mb-3">
                                    {{ $t('info-block.details[0].title') }}
                                </summary>
                                {{ $t('info-block.details[0].description') }}
                            </details>

                            <details open>
                                <summary class="cursor-pointer font-bold">
                                    {{ $t('info-block.details[1].title') }}
                                </summary>
                                <ul>
                                    <li>
                                        {{ $t('info-block.details[1].description[0]') }}
                                    </li>
                                    <li>
                                        {{ $t('info-block.details[1].description[1]') }}
                                    </li>
                                    <li>
                                        {{ $t('info-block.details[1].description[2]') }}
                                    </li>
                                </ul>
                            </details>
                        </div>
                    </div>
                    <div class="xl:basis-5/12 basis-3/12 md:mt-0 mt-12">
                        <div class="prose prose-slate prose-md md:prose-xl">
                            <h2>{{ $t('contacts') }}</h2>
                            <p>
                                <span class="whitespace-nowrap inline-block">UK <a href="tel:+447520615425">+44 752 061 54 25</a></span>
                                <span class="ml-3 whitespace-nowrap inline-block"> UA <a href="tel:+380914812006">+380 91 481 20 06</a></span>
                                <span class="ml-3 whitespace-nowrap inline-block"> PL <a href="tel:+48732232843">+48 732 23 28 43</a></span>
                            </p>

                            <p class="whitespace-nowrap">
                                <a href="mailto:help@homes4ukraine.org">help@homes4ukraine.org</a>
                            </p>
                            <a href="https://t.me/homes4ukraine" target="_blank" class="button button-telegram">
                                <i class="telegram"></i>
                                @Homes4Ukraine
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hrp pt-8 pb-12">
                <div class="prose prose-md ">
                    <p class="opacity-20 text-slate-900">
                        ДОПОМОГА БЕЗКОШТОВНА
                    </p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HomeView',
  computed: {
      localeTitle() {
          return this.$i18n.locale === 'uk' ? '🇬🇧 English' : '🇺🇦 Українська';
      }
  },
  methods: {
      changeLocale() {
          this.$i18n.locale = this.$i18n.locale === 'uk' ? 'en' : 'uk';
          this.$router.push({ path: `/${this.$i18n.locale}` });
      }
  }
}
</script>
